import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { cloneDeep, isEmpty, orderBy } from 'lodash';
import { UserIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../layouts/StackedLayout';
import Form from '../../components/forms/FormLayout';
import FieldSet from '../../components/forms/fieldSets/TwoColumnFieldSet';
import { Button } from '../../components/buttons';
import {
  Input,
  Select,
  SelectDistrict,
  RadioGroupHorizontal,
  InlineCheckbox,
  UploadIcon
} from '../../components/forms/fields';
import { getBranches } from '../../services/branch';
import { createCoachingStudent, updateStudent, getStudent } from '../../services/student';
import { getNormalizedDateTime, handleUploadPublicImage } from '../../utils';


const genderOptions = [
  { name: 'Male', value: 'male' },
  { name: 'Female', value: 'female' },
];
const institutionTypes = [
  { name: 'School', value: 'school' },
  { name: 'College', value: 'college' },
  { name: 'University', value: 'university' },
];

export default function StudentRegistrationPage() {
  const navigate = useNavigate();
  const params = useParams();
  const studentId = params.studentId;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const [branch, setBranch] = useState(null);
  const [student, setStudent] = useState(null);
  const [name, setName] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [email, setEmail] = useState(null);
  const [gender, setGender] = useState('male');
  const [currentInstitutionType, setCurrentInstitutionType] = useState(null);
  const [institutionName, setInstitutionName] = useState(null);
  const [district, setDistrict] = useState(null);
  const [fatherName, setFatherName] = useState('');
  const [fatherOccupation, setFatherOccupation] = useState('');
  const [fatherMobile, setFatherMobile] = useState('');
  const [motherName, setMotherName] = useState('');
  const [motherOccupation, setMotherOccupation] = useState('');
  const [motherMobile, setMotherMobile] = useState('');
  const [sendSmsToFather, setSendSmsToFather] = useState(false);
  const [sendSmsToMother, setSendSmsToMother] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [bloodGroup, setBloodGroup] = useState('');
  const [dob, setDob] = useState(null);
  const [branches, setBranches] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function _fetchData() {
      try {
        if (currentUser?.branch) {
          setBranch(currentUser.branch._id);
        } else {
          const _branches = await getBranches();
          setBranches(orderBy(_branches, 'name'));
        }

        if (studentId) {
          const _student = await getStudent(studentId);
          setStudent(_student);
          setName(_student.name);
          setBranch(_student?.branch?._id);
          setMobile(_student.mobile);
          setEmail(_student.email);
          setGender(_student.gender);
          setDistrict(_student.district);
          setFatherName(_student.fatherName);
          setFatherOccupation(_student.fatherOccupation);
          setFatherMobile(_student.fatherMobile);
          setSendSmsToFather(_student.sendSmsToFather);
          setMotherName(_student.motherName);
          setMotherOccupation(_student.motherOccupation);
          setMotherMobile(_student.motherMobile);
          setSendSmsToMother(_student.sendSmsToMother);
          setProfilePicture(_student.profilePicture);
          setBloodGroup(_student.bloodGroup);
          setDob(_student.dob);
          const _currentInstitutionType = _student.currentInstitutionType;
          setCurrentInstitutionType(_currentInstitutionType);
          if (_currentInstitutionType) {
            setInstitutionName(_student[_currentInstitutionType])
          }
        }
      } catch (error) {
        toast.error(error.message);
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };
    _fetchData();
  }, []);

  const handleProfilePicture = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setProfilePicture(imageUrl);
      return imageUrl;
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const saveStudent = async (event) => {
    try {
      event.preventDefault();
      setUpdating(true);
      let payload = {
        branch,
        name,
        mobile,
        email,
        gender,
        district,
        currentInstitutionType,
        fatherName,
        fatherOccupation,
        fatherMobile,
        sendSmsToFather,
        motherName,
        motherOccupation,
        motherMobile,
        sendSmsToMother,
        profilePicture,
        bloodGroup,
        dob
      };
      if (currentInstitutionType && institutionName) {
        payload[currentInstitutionType] = institutionName
      }
  
      if (studentId) {
        await updateStudent(studentId, payload);
        toast.success('Student updated successfully.');
      } else {
        const newStudent = await createCoachingStudent(payload);
        toast.success('Student registerred successfully.');
        navigate('/students/admission', {
          state: { registrationNo: newStudent.registrationNo }
        });
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };


  const goBack = () => {
    navigate(`/students`);
  };

  const isDisabled = () => {
    return updating || !branch || !name || !mobile || !gender;
  };

  return (
    <StackedLayout loading={loading}>
      <div className="flex justify-between border-b border-gray-200 pb-6">
        <div>
          <h1 className="text-xl font-semibold text-gray-900">
            {student?.name || 'New student registration'}
          </h1>
          {student &&
          <p className="mt-2 text-base text-gray-500">
            Edit information for this student.
          </p>}
        </div>
        
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Button label="Go back" onClick={goBack} />
        </div>
      </div>

      <Form
        submitText={student ? 'Save' : 'Create'}
        disabled={isDisabled()}
        onSubmit={saveStudent}
        onCancel={goBack}

      >
        {!currentUser.branch &&
        <FieldSet
          title="Branch"
          subTitle="Select branche name."
        >
          <Select disabled={student} label="Branch" value={branch} options={branches} onChange={(event) => setBranch(event.target.value)} />

        </FieldSet>}

        <FieldSet
          title="Student basic information"
          subTitle="Set name, mobile, email an dother persomal information."
        >
          <Input col={4} label="Name" value={name} onChange={(event) => setName(event.target.value)} />
          <Input label="Mobile" disabled={!isEmpty(studentId)} value={mobile} onChange={(event) => setMobile(event.target.value)} />

          <Input label="Email" value={email} onChange={(event) => setEmail(event.target.value)} />
          <Input col={2} label="Bloog group" value={bloodGroup} onChange={(event) => setBloodGroup(event.target.value)} />
          <Input col={2} type="date" label="Date of birth" value={getNormalizedDateTime(dob, 'YYYY-MM-DD')} onChange={(event) => setDob(event.target.value)} />
          <UploadIcon label="Photo" actionName="Change" Icon={UserIcon} image={profilePicture} onUpload={handleProfilePicture} />
          <RadioGroupHorizontal label="Gender" value={gender} options={genderOptions} onChange={(event) => setGender(event.target.value)} />
        </FieldSet>

        <FieldSet
          title="Academic information"
          subTitle="Set academic information for this student."
        >
          <SelectDistrict label="District" value={{label: district, value: district}} onChange={(item) => setDistrict(item.value)} />
          <Select label="Institution type" value={currentInstitutionType} options={institutionTypes} onChange={(event) => setCurrentInstitutionType(event.target.value)} />
          <Input col={4} label="Institution Name" value={institutionName} onChange={(event) => setInstitutionName(event.target.value)} />
        </FieldSet>

        <FieldSet
          title="Father's information"
          subTitle="Set father's contacts."
        >
          <Input col={4} label="Father's Name" value={fatherName} onChange={(event) => setFatherName(event.target.value)} />
          <Input col={2} label="Father's mobile" value={fatherMobile} onChange={(event) => setFatherMobile(event.target.value)} />
          <Input col={4} label="Father's occupation" value={fatherOccupation} onChange={(event) => setFatherOccupation(event.target.value)} />
        </FieldSet>

        <FieldSet
          title="Mother's information"
          subTitle="Set mothers's contacts."
        >
          <Input col={4} label="Mother's Name" value={motherName} onChange={(event) => setMotherName(event.target.value)} />
          <Input col={2} label="Mother's mobile" value={motherMobile} onChange={(event) => setMotherMobile(event.target.value)} />
          <Input col={4} label="Mother's occupation" value={motherOccupation} onChange={(event) => setMotherOccupation(event.target.value)} />
        </FieldSet>

        <FieldSet
          title="SMS numbres"
          subTitle="Select the numbers to send SMS."
        >
          <InlineCheckbox
            label="Primary mobile"
            description={`Send sms to ${mobile}`}
            checked={true}
            disabled={true}
          />
          <InlineCheckbox
            label="Father's mobile"
            description={`Send sms to ${fatherMobile}`}
            checked={sendSmsToFather}
            onChange={() => setSendSmsToFather(!cloneDeep(sendSmsToFather))}
          />
          <InlineCheckbox
            label="Mother's mobile"
            description={`Send sms to ${motherMobile}`}
            checked={sendSmsToMother}
            onChange={() => setSendSmsToMother(!cloneDeep(sendSmsToMother))}
          />
        </FieldSet>
      </Form>
    </StackedLayout>
  )
};