import http from '../helpers/http';


export const getLessons = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/lessons`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const searchLessons = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/lessons/search`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createLesson = async (payload) => {
  try {
    const { data } = await http.post(`/api/lessons`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateLesson = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/lessons/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeLessonStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/lessons/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const addLessonVideo = async (id) => {
  try {
    const { data } = await http.put(`/api/lessons/${id}/add-video`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const removeLessonVideo = async (id) => {
  try {
    const { data } = await http.put(`/api/lessons/${id}/remove-video`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const deleteLesson = async (id) => {
  try {
    const { data } = await http.delete(`/api/lessons/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
