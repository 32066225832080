import { useSelector } from 'react-redux';

import {
  convertToBanglaNumber,
  getDiscountedFee,
  courseTypeLabelMapping
} from '../../utils';


export default function CourseCardGrid(props) {
	const { course, hideThumbnail, isCompact } = props;
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;

	return (
		<div
			className="rounded lg:rounded-lg bg-white cursor-pointer shadow h-full"
			onClick={() => {
        if (course?.courseType === 'offline' || currentInstitute?.permissions?.websiteEnabled) {
          window.location.href = `/learn/courses/${course._id}/details`;
        } else {
          const url = `${process.env.REACT_APP_LEARN_URL}/courses/${course._id}/details`;
          window.open(url, '_blank').focus();
        }
      }}
		>
			{!hideThumbnail && <img className="w-full rounded-t lg:rounded-t-lg" src={course.thumbnail} alt="Sunset in the mountains" />}
			<div className="px-4 pt-4">
				{!isCompact &&
        <div className="flex justify-start">
					<span className=" bg-green-100 rounded px-3 py-1 leading-5 text-xs font-semibold text-green-800 mr-2 mb-2">{course?.program?.nameBN || course?.program?.name}</span>
					<span className=" bg-green-100 rounded px-3 py-1 leading-5 text-xs font-semibold text-gray-800 mr-2 mb-2">{courseTypeLabelMapping[course.courseType]}</span>
				</div>}
				<div className="font-semibold text-base leading-7 text-left mt-2 mb-2">{course.name}</div>
			</div>
			
      {!isCompact &&
      <div className="px-4 pb-4 pt-2 flex justify-start">
				{course.isFree &&
				<p className=" text-green-600 text-xl leading-6 flex flex-wrap items-center">ফ্রি</p>}

				{!course.isFree &&
				<>
					{course?.discount > 0 ?
					<>
						<div className="mr-1 rounded-full bg-red-100 text-red-600">
							<p className="px-2">
								{course?.discountType === 'flat' && '৳'}{convertToBanglaNumber(course?.discount)}{course?.discountType === 'percentage' && '%'} ছাড়!
							</p>
						</div>
						<p className="font-medium space-x-1">
							<span className="text-xl text-green-600">৳{convertToBanglaNumber(getDiscountedFee(course))}</span>
							<span className="mr-1 line-through decoration-red-600 text-gray-400">{convertToBanglaNumber(course.fee)}</span>
						</p>
					</>
					:<p className=" text-green-600 text-xl leading-6 flex flex-wrap items-center">৳{convertToBanglaNumber(course.fee)}</p>}
				</>}
			</div>}
		</div>
	)
};

// export default function CourseCardGrid(props) {
//   const instituteState = useSelector((state) => state.institute);
//   const currentInstitute = instituteState?.instituteInfo;
//   const { course, isCompact, hideThumbnail } = props;
//   const grade = course?.grade || course.program?.grade;
//   const group = course?.group;

//   return (
//     <div     
//       className="flex flex-col pb-2 rounded-lg bg-white shadow cursor-pointer overflow-hidden"
//       onClick={() => {
//         if (course?.courseType === 'offline' || currentInstitute?.permissions?.websiteEnabled) {
//           window.location.href = `/learn/courses/${course._id}/details`;
//         } else {
//           const url = `${process.env.REACT_APP_LEARN_URL}/courses/${course._id}/details`;
//           window.open(url, '_blank').focus();
//         }
//       }}
//     >
//       <div>
//         {!hideThumbnail &&
//         <div className="w-full">
//           <img src={course.thumbnail} alt="" />
//         </div>}

//         <div className="flex flex-1 flex-col mx-4 mt-2">
//           <h3 className="text-sm font-medium text-purple-700 space-x-2">
//             <span
//               className={classNames(
//                 'px-2 rounded-full text-xs w-20 text-center',
//                 course.courseType === 'offline' && 'bg-sky-100 text-sky-600',
//                 course.courseType === 'live' && 'bg-red-100 text-red-600',
//                 course.courseType === 'recorded' && 'bg-indigo-100 text-indigo-600',
//                 course.courseType === 'exam' && 'bg-green-100 text-green-600'
//               )}
//             >
//               {courseTypelabels[course.courseType]}
//             </span>
//             <span>{course.name}</span>
//           </h3>
          
//           {/* {!isCompact &&
//           <p className="flex items-center space-x-1">
//             <span>
//               <img className="h-4 w-4" src={course?.institute?.icon} alt="" />
//             </span>
//             <span className="text-sm text-gray-900">{course?.institute?.name}</span>
//           </p>} */}

//           {!isCompact &&
//           <div className="flex flex-1 my-4 flex-col space-y-4 justify-end">
//             <p className="text-sm text-gray-500">
//             {course?.program?.nameBN || course?.program?.name}
//               {/* {gradeLabelMapping[grade]} {!includes(['job', 'skill'], grade) && ` (${groupLabelMapping[group] || 'সকল'} বিভাগ)`} */}
//             </p>
//             <div className="flex items-center text-gray-500">
//               {!course?.isFree && course?.fee > 0 ?
//               <>
//                 {course?.discount > 0 ?
//                 <>
//                   <div className="-mx-4 mr-1 rounded-r-full bg-red-100 text-red-600">
//                     <p className="px-2">
//                       {course?.discountType === 'flat' && '৳'}{convertToBanglaNumber(course?.discount)}{course?.discountType === 'percentage' && '%'} ছাড়!
//                     </p>
//                   </div>
//                   <p className="text-base font-medium space-x-1">
//                     <span className="text-green-600">৳{convertToBanglaNumber(getDiscountedFee(course))}</span>
//                     <span className="mr-1 line-through decoration-red-600 text-gray-400">{convertToBanglaNumber(course.fee)}</span>
//                   </p>
//                 </>
//                 :<p className="text-base font-medium text-green-600">৳{convertToBanglaNumber(course.fee)}</p>}
//               </>
//               : <p className="text-base font-medium text-red-700">ফ্রি</p>}
//             </div>
//           </div>}
//         </div>
//       </div>
//     </div>
//   )
// };
