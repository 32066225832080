import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { omit, isEmpty, capitalize, cloneDeep, findIndex, includes } from 'lodash';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

import CategoryListingLayout from '../../layouts/CategoryListingLayout';
import StackedLayout from '../../layouts/StackedLayout';
import SectionHeader from '../../components/SectionHeader';
import Table from '../../components/tables/StackedTable';
import { SubmitButton, ButtonWithIcon } from '../../components/buttons';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import AddCompetitionParticipantForm from './components/AddCompetitionParticipantForm';
import DownloadCompetitionParticipantsModal from '../../components/modals/DownloadCompetitonParticipantsModal';
import {
  getCompetition,
  getCompetitionParticipants,
  addCompetitionParticipant } from '../../services/competition';
import { getNormalizedDateTime } from '../../utils';


const filters = [
  {
    name: 'Status',
    fieldName: 'status',
    options: [
      {label: 'Active', value: 'active'},
      {label: 'Inactive', value: 'inactive'}
    ]
  }
];
const headers = ['Name', 'Mobile', 'Gender', 'District', 'Institute', 'Registered at', 'Branch'];

export default function CompetitionParticipantsPage() {
  const navigate = useNavigate();
  const params = useParams();
  const competitionId = params.id;
  const currentUser = useSelector((state) => state.user)?.userInfo;
  const currentBranch = useSelector((state) => state.branch)?.branchInfo;
  const [loading, setLoading] = useState(true);
  const [competition, setCompetition] = useState(null);
  const [participants, setParticipatns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState({});
  const [queryString, setQueryString] = useState(null);
  const [statusFilters, setStatusFilters] = useState([]);
  const [showDownloadCompetitionParticipantsModal, setShowDownloadCompetitionParticipantsModal] = useState(false);
  const [openAddParticipantsForm, setOpenAddParticipantForm] = useState(false);
  const [breadcrumbPages, setBreadcrumbPages] = useState(null);

  useEffect(() => {
    async function fetchCompetition() {
      try {
        const _competition = await getCompetition(competitionId);
        setCompetition(_competition);

        setBreadcrumbPages([
          {name: 'Competitions', href: `/competitions`, current: false},
          {name: _competition.name, current: true}
        ])
      } catch (error) {
        console.log(error);
        toast.error(error.message);
        navigate(-1);
      }
    };
    fetchCompetition();
  });

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    currentPage,
    queryString,
    statusFilters,
  ]);

  const fetchData = async () => {
    try {
      let params = {
        isPaging: true,
        page: currentPage,
        limit: 10,
        search: queryString,
        sortBy: 'createdAt',
        sortOrder: 'desc'
      };

      if (statusFilters.length > 0) {
        params.status = statusFilters.length === 1 ? statusFilters[0] : statusFilters;
      }

      const data = await getCompetitionParticipants(competitionId, params);
      setParticipatns(data.docs);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const onSearch = async (event, queryString) => {
    event.preventDefault();
    const q = !isEmpty(queryString) ? queryString: null;
    setQueryString(q);
    setCurrentPage(1);
    setStatusFilters([]);
    setLoading(true);
  };

  const applyFilterChanges = (event, filterIndex) => {
    const _value = event.target.value;
    const _statusFilters = cloneDeep(statusFilters);
    const fieldName = filters[filterIndex].fieldName;
    
    if (fieldName === 'status') {
      const valueIndex = findIndex(_statusFilters, item => item === _value);
      if (valueIndex === -1) {
        _statusFilters.push(_value);
      } else {
        _statusFilters.splice(valueIndex, 1);
      }
      setStatusFilters(_statusFilters);
    }
  };

  // const handleChangeClientStatus = async (event) => {
  //   event.preventDefault();
  //   try {
  //     await changeLMSClientStatus(selectedCompetition._id);
  //     await fetchData();
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  const handleAddParticipant = async (event, payload) => {
    try {
      event.preventDefault();
      await addCompetitionParticipant(competition._id, payload);
      toast.success('Participant added successfully');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const hasAccess = () => {
    return includes(['owner', 'admin'], currentUser?.role) ||
    currentUser?.permissions?.canManageCompetitions ||
    (currentBranch?.permissions?.canManageCompetitionParticipants &&
      (currentUser?.role === 'branchAdmin' || 
      currentUser?.permissions?.canManageCompetitionParticipants));
  };

  return (
    <StackedLayout
      loading={loading}
      // title={`${competition?.name} participants`}
      breadcrumbPages={breadcrumbPages}
      // subTitle="List of all participants for this competition"
      // hideCreateNewButton={!hasAccess()}
      // createButtonText="Add participant"
      // onCreate={() => setOpenAddParticipantForm(true)}
    >
      {/* {showStatusModal &&
      <ConfirmModal
        title={selectedCompetition.isActive ? 'Disable client' : 'Enable client'} 
        description={`Are you sure to ${selectedCompetition.isActive ? 'disable' : 'enable'} this client? Please double check before performing this action.`}
        actionName={selectedCompetition.isActive ? 'Disable' : 'Enable'}
        onConfirm={handleChangeClientStatus}
        onCancel={() => {
          setSelectedCompetition(null);
          setShowStatusModal(false);
        }}
      />} */}

      {showDownloadCompetitionParticipantsModal&&
      <DownloadCompetitionParticipantsModal
        competition={competition}
        currentUser={currentUser}
        onCancel={() => setShowDownloadCompetitionParticipantsModal(false)}
      />}

      {openAddParticipantsForm &&
      <AddCompetitionParticipantForm
        competition={competition}
        onSave={handleAddParticipant}
        onClose={() => {
          setOpenAddParticipantForm(false);
        }}
      />}

      <SectionHeader
        title={`${competition?.name} participants`}
        // subTitle="List of all students of your institution."
      >
        <div>
          <div className="flex space-x-4"> 
            <ButtonWithIcon label="Download" Icon={ArrowDownTrayIcon} onClick={() => setShowDownloadCompetitionParticipantsModal(true)} />
            {hasAccess() && <SubmitButton label="Add participant" onClick={() => setOpenAddParticipantForm(true)} />}
          </div>
        </div>
      </SectionHeader>

      <Table 
        headers={headers}
        itemsCount={participants?.length}
        pagingData={pagingData}
        // queryString={queryString}
        // onSearch={onSearch}
        onPageChange={onPageChange}
      >
        {participants?.map((participant) => (
          <tr key={participant._id} className="text-sm text-center">
            <td className="py-4 pl-4 pr-3 text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
              {participant.user.name}
            </td>
            <td className="pr-3 py-4 text-gray-500">{participant.user.mobile}</td>
            <td className="pr-3 py-4 text-gray-500">{participant.gender}</td>
            <td className="pr-3 py-4 text-gray-500">{participant.district}</td>
            <td className="pr-3 py-4 text-gray-500">{participant.academicInstitute}</td>
            <td className="pr-3 py-4 text-gray-500">{getNormalizedDateTime(participant.createdAt)}</td>
            <td className="pr-3 py-4 text-gray-500">{participant?.branch?.name || 'Online'}</td>
            {/* <td className="pr-3 py-4 text-gray-500">{participant.status}</td> */}
            {/* <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right font-medium sm:pr-0">
              <ActionItems 
                competition={competition}
                onAddParticipants={() => {
                  setSelectedCompetition(competition);
                  setOpenAddParticipantForm(true);
                }}
                onDownloadResult={() => {
                  setSelectedCompetition(competition);
                  setShowDownloadCompetitionParticipantsModal(true);
                }}

              />
            </td> */}
          </tr>
        ))}
      </Table>
    </StackedLayout>
  )
};

// function ActionItems(props) {
//   const { competition, onAddParticipants, onStatusChange, onDownloadResult } = props;
//   return (
//     <ActionMenu>
//       <div className="py-1">
//         <MenuItem label="View landing page" href={`/learn/competitions/${competition.slug}`} />
//       </div>
//       <div className="py-1">
//         <MenuItem label="Edit" href={`/competitions/${competition._id}/edit`} />
//       </div>
//       <div className="py-1">
//         <MenuItem label="Participants" href={`/competitions/${competition._id}/participants`} />
//       </div>
//       {/* <div className="py-1">
//         <MenuItem label="Download result" onClick={onDownloadResult} />
//       </div> */}
//       {/* <div className="py-1">
//         <MenuItem label={competition.status === 'active' ? 'Disable' : 'Enable'} onClick={onStatusChange} />
//       </div> */}
//     </ActionMenu>
//   )
// };

