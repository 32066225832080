import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { QuestionMarkCircleIcon, FolderIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { findIndex, forEach, isEmpty, orderBy } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import FolderBreadcrumb from '../../components/FolderBreadcrumb';
import { ButtonWithIcon } from '../../components/buttons';
import InputModal from '../../components/modals/InputModal';
import Table from '../../components/tables/StackedTable';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import ConfirmModal from '../../components/modals/ConfirmModal';
import LatexText from '../../components/LatexText';
import QuestionEditor from './components/QuestionEditPanel';
import CreativeQuestionEditor from './components/CreativeQuestionEditor';
import { getFolder, createFolder, getFolders, updateFolder, deleteFolder } from '../../services/folder';
import { getQuestions, createQuestion, updateQuestion, deleteQuestion } from '../../services/question';
import { getCreaiveQuestions, createCreativeQuestion, updateCreativeQuestion, deleteCreativeQuestion} from '../../services/creativeQuestion'


const sidebarNavigation = [
  { name: 'MCQs', value: 'mcq', href: '/contents/questions/mcq' },
  { name: 'Creative Questions', value: 'cq', href: '/contents/questions/cq' },
];
const mcqHeaders = ['Name/Question', 'Created by'];
const cqHeaders = ['Name/Question', 'Created by', 'Questions', 'Marks'];

export default function QuestionsPage(props) {
  const params = useParams();
  const navigate = useNavigate();
  const folderId = params?.folderId;
  const { questionType } = props;
  const folderType = questionType;
  const [items, setItems] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [currentNaviation, setCurrentNavigation] = useState(null);
  const [queryString, setQueryString] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [showFolderDeleteModal, setShowFolderDeleteModal] = useState(false);
  const [showQuestionDeleteModal, setShowQuestionDeleteModal] = useState(false);
  const [openQuestionEditor, setOpenQuestionEditor] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchInitialData() {
      const navIndex = findIndex(sidebarNavigation, item => item.value === questionType);
      setCurrentNavigation(sidebarNavigation[navIndex]);
      
      if (folderId) {
        try {
          const _folder = await getFolder(folderId);
          setCurrentFolder(_folder);
        } catch (error) {
          navigate(-1);
        }
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    questionType,
    queryString,
    currentFolder
  ]);

  const fetchData = async () => {
    const questions = await fetchQuestions();
    let folders = await fetchFolders();
    forEach(folders, folder => folder.isFolder = true);
    const _items = folders.concat(questions);
    setItems(_items);
  };

  const fetchQuestions = async () => {
    try {
      let params = {
        search: queryString
      };

      if (currentFolder) {
        params.folder = currentFolder._id;
      }
      let data = [];
      if (questionType === 'mcq') {
        data = await getQuestions(params);
      } else {
        data = await getCreaiveQuestions(params);
      }
      return orderBy(data, 'createdAt', 'asc');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchFolders = async () => {
    try {
      let params = {
        contentType: folderType,
        search: queryString,
      };

      if (currentFolder) {
        params.parentFolder = currentFolder._id;
      } else {
        params.isRoot = true;
      }
      const data = await getFolders(params);
      return orderBy(data, 'name');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDeleteQuestion = async (event) => {
    try {
      event.preventDefault();
      if (questionType === 'mcq') {
        await deleteQuestion(selectedQuestion._id);
      } else {
        await deleteCreativeQuestion(selectedQuestion._id);
      }
      toast.success('Question deleted successfully.');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const onSearch = async (event, queryString) => {
    event.preventDefault();
    const q = !isEmpty(queryString) ? queryString: null;
    setQueryString(q);
    setLoading(true);
  };

  const saveFolder = async (event, name) => {
    try {
      event.preventDefault();
      if (selectedFolder) {
        const payload = { name: name };
        await updateFolder(selectedFolder._id, payload);
        toast.success('Folder updated successfully.');
      } else {
        let payload = { name: name, contentType: folderType };
        if (currentFolder) { payload.parentFolder = currentFolder._id };
        await createFolder(payload);
        toast.success('Folder created successfully');
      }
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleDeleteFolder = async (event) => {
    try {
      event.preventDefault();
      await deleteFolder(selectedFolder._id);
      toast.success('Folder deleted successfully');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSelectedFolder(null);
    }
  };

  const handleCreateNew = (itemName) => {
    if (itemName === 'folder') {
      setOpenFolderModal(true);
    } else {
      setOpenQuestionEditor(true);
    }
  };

  const saveQuestion = async (event, payload) => {
    try {
      event.preventDefault();
      if (selectedQuestion?._id) {

        if (questionType === 'mcq') {
          await updateQuestion(selectedQuestion._id, payload);
        } else {
          await updateCreativeQuestion(selectedQuestion._id, payload);
        }
        toast.success('Question updated successfully.');
      } else {
        payload.folder = currentFolder?._id;

        if (questionType === 'mcq') {
          await createQuestion(payload);
        } else {
          await createCreativeQuestion(payload);
        }
        toast.success('Quested created successtully.');
      }
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={currentNaviation?.name}
    >
      {openFolderModal &&
      <InputModal
        label={selectedFolder ? 'Rename folder' : 'New folder name'}
        currentValue={selectedFolder?.name}
        actionName={selectedFolder ? 'Rename' : 'Create'}
        onSubmit={saveFolder}
        onCancel={() => setOpenFolderModal(false)}
      />}

      {showFolderDeleteModal &&
      <ConfirmModal
        title="Delete folder"
        description={`Are you sure to delete folder? Please note that all the child folders and contents for this folder will be deleted as well.`}
        actionName="Delete"
        onConfirm={handleDeleteFolder}
        onCancel={() => {
          setSelectedFolder(null);
          setShowFolderDeleteModal(false);
        }}
      />}

      {showQuestionDeleteModal &&
      <ConfirmModal
        title="Delete question"
        description={`Are you sure to delete this question? Please double check before performing this action.`}
        actionName="Delete"
        onConfirm={handleDeleteQuestion}
        onCancel={() => {
          setSelectedQuestion(null);
          setShowQuestionDeleteModal(false);
        }}
      />}

      {questionType === 'mcq' && openQuestionEditor && 
      <QuestionEditor
        question={selectedQuestion}
        onSave={saveQuestion}
        onClose={() => {
          setSelectedQuestion(null);
          setOpenQuestionEditor(false);
        }}
      />}

      {questionType === 'cq' && openQuestionEditor && 
      <CreativeQuestionEditor
        question={selectedQuestion}
        onSave={saveQuestion}
        onClose={() => {
          setSelectedQuestion(null);
          setOpenQuestionEditor(false);
        }}
      />}
      
      <div className="mx-2 flex justify-end space-x-4">
        <ButtonWithIcon Icon={FolderIcon} label="New folder" onClick={() => handleCreateNew('folder')} />
        <ButtonWithIcon Icon={QuestionMarkCircleIcon} label="New question" onClick={() => handleCreateNew('question')} />
      </div>

      {currentFolder &&
      <div className="px-4 sm:px-6 lg:px-8">
        <FolderBreadcrumb currentFolder={currentFolder} homeUrl={currentNaviation.href} />
      </div>}

      <div className="flex flex-col">
        <Table
          itemsCount={items.length}
          headers={questionType === 'mcq' ? mcqHeaders : cqHeaders}
          queryString={queryString}
          onSearch={onSearch}
        >
          {items?.map((item) => (
            <tr key={item._id} className="text-sm text-center">
              <td className="py-4 pl-4 pr-3 text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                <div className="flex items-center">
                  <div>
                    {item.isFolder ?
                    <FolderIcon className="h-5 w-5" />
                    : <QuestionMarkCircleIcon className="h-5 w-5" />}
                  </div>
                  <div className="ml-2">
                    <div className="font-medium text-gray-900">
                      {item.isFolder ?
                      <a
                        href={`/contents/questions/${questionType}/folder/${item._id}`}
                        className="hover:underline"
                      >
                        {item?.name}
                      </a>
                      :
                      <p
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedQuestion(item);
                          setOpenQuestionEditor(true);
                        }}
                      >
                        <LatexText text={item?.question || item.name} />
                      </p>}
                    </div>
                  </div>
                </div>
              </td>

              <td className="pr-3 py-4 text-sm text-gray-500">
                <div className="flex items-center justify-center">
                  <div>
                    {item?.createdBy?.profilePicture ?
                    <img className="h-5 w-5 rounded-full" src={item.createdBy.profilePicture} />
                    : <UserCircleIcon className="h-5 w-5" />}
                  </div>
                  <div className="ml-2">
                    <div className="font-medium text-gray-900">
                      {item?.createdBy?.name.split(' ').slice(0, 2).join(' ')}
                    </div>
                  </div>
                </div>
              </td>

              {questionType === 'cq' &&
              <td className="pr-3 py-4 text-sm text-gray-500">
                {item?.questionSet?.length || '-'}
              </td>}

              {questionType === 'cq' &&
              <td className="pr-3 py-4 text-sm text-gray-500">
                {item?.totalMarks || '-'}
              </td>}

              <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                {item.isFolder ?
                <FolderActionItems
                  onRename={() => {
                    setSelectedFolder(item);
                    setOpenFolderModal(true);
                  }}
                  onDelete={() => {
                    setSelectedFolder(item);
                    setShowFolderDeleteModal(true);
                  }}
                />
                :
                <ActionItems 
                  onEdit={() => {
                    setSelectedQuestion(item);
                    setOpenQuestionEditor(true);
                  }}
                  onDelete={() => {
                    setSelectedQuestion(item);
                    setShowQuestionDeleteModal(true);
                  }}
                />}
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </StackedLayout>
  )
};

function ActionItems(props) {
  const { onEdit, onDelete } = props;
  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" onClick={onEdit} />
      </div>
      
      <div className="py-1">
        <MenuItem label="Delete" isDanger={true} onClick={onDelete} />
      </div>
    </ActionMenu>
  )
};

function FolderActionItems(props) {
  const { onRename, onDelete } = props;
  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Rename" onClick={onRename} />
      </div>
      
      {/* <div className="py-1 text-red-600">
        <MenuItem label="Delete" isDanger={true} onClick={onDelete} />
      </div> */}
    </ActionMenu>
  )
};
