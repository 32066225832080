import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { omit, capitalize, cloneDeep, findIndex, includes } from 'lodash';

import CategoryListingLayout from '../../layouts/CategoryListingLayout';
import Table from '../../components/tables/StackedTable';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import ChangeBatchForm from './components/ChangeBranchForm';
import { getUser } from '../../services/user';
import { changeBatch, getEnrolledCoursesForStudent } from '../../services/enrolledCourse';
import { getNormalizedDateTime } from '../../utils';


const filters = [
  {
    name: 'Status',
    fieldName: 'status',
    options: [
      { label: 'Active', value: 'active' },
      { label: 'Suspended', value: 'suspended' },
      { label: 'Cancelled', value: 'cancelled' },
    ]
  }
];
const headers = ['Course', 'Enrolled at', 'Program', 'Branch', 'Batch', 'Roll', 'Course type', 'Status'];

export default function StudentCoursesPage() {
  const navigate = useNavigate();
  const params = useParams();
  const studentId = params.studentId;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo;
  const branchState = useSelector((state) => state.branch);
  const currentBranch = branchState?.branchInfo;
  const [student, setStudent] = useState(null);
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState(null);
  const [statusFilters, setStatusFilters] = useState([]);
  const [selectedEnrolledCourse, setSelectedEnrolledCourse] = useState(null);
  const [openChangeBatchForm, setOpenChangeBatchForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const breadcrumbPages = [
    { name: 'Students', href: '/students' },
    { name: 'Enrolled courses', current: true },
  ];

  useEffect(() => {
    async function _fetchData() {
      try {
        const user = await getUser(studentId);
        setStudent(user);
      } catch (error) {
        navigate(-1);
      }
    };
    _fetchData();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchEnrolledCourses();
      setLoading(false);
    };
    _fetchData();
  }, [
    currentPage,
    statusFilters
  ])

  const fetchEnrolledCourses = async () => {
    try {
      let params = {
        isPaging: true,
        page: currentPage,
        limit: 10,
        sortyBy: 'createdAt',
        sortOrder: 'desc'
      };

      if (statusFilters.length > 0) {
        params.status = statusFilters.length === 1 ? statusFilters[0] : statusFilters;
      }

      const data = await getEnrolledCoursesForStudent(studentId, params);
      setEnrolledCourses(data?.docs || []);
      setPagingData(omit(data, 'docs'));
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChangeBatch = async (event, payload) => {
    try {
      event.preventDefault();
      await changeBatch(selectedEnrolledCourse._id, payload);
      toast.success('Batch changed successfully');
      await fetchEnrolledCourses();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  const applyFilterChanges = (event, filterIndex) => {
    const _value = event.target.value;
    const _statusFilters = cloneDeep(statusFilters);
    const fieldName = filters[filterIndex].fieldName;
    
    if (fieldName === 'status') {
      const valueIndex = findIndex(_statusFilters, item => item === _value);
      if (valueIndex === -1) {
        _statusFilters.push(_value);
      } else {
        _statusFilters.splice(valueIndex, 1);
      }
      setStatusFilters(_statusFilters);
    }
  };

  return (
    <CategoryListingLayout
      loading={loading}
      breadcrumbPages={breadcrumbPages}
      title={`Enrolled courses`}
      subTitle={`Student: ${student?.name}; registration no: ${student?.registrationNo}`}
      filters={filters}
      onFilterChange={applyFilterChanges}
    >
      {openChangeBatchForm &&
      <ChangeBatchForm
        enrolledCourse={selectedEnrolledCourse}
        onSave={handleChangeBatch}
        onClose={() => {
          setSelectedEnrolledCourse(null);
          setOpenChangeBatchForm(false);
        }}
      />}
      <Table
        headers={headers}
        items={enrolledCourses}
        itemsCount={enrolledCourses.length}
        pagingData={pagingData}
        onPageChange={onPageChange}
      >
        {enrolledCourses?.map((enrolledCourse) => (
          <tr key={enrolledCourse._id} className="text-center">
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 text-ellipsis text-left">
              {enrolledCourse?.course?.name}
            </td>
            <td className="pr-3 py-4 text-sm text-gray-500">{getNormalizedDateTime(enrolledCourse?.createdAt)}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{enrolledCourse?.program?.name}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{enrolledCourse?.branch?.name || 'Online'}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{enrolledCourse?.batch?.name || '-'}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{enrolledCourse?.rollNo || '-'}</td>
            <td className="pr-3 py-4 text-sm text-gray-500">{enrolledCourse?.course?.courseType === 'exam' ? 'Online exam' : enrolledCourse?.course?.courseType}</td>
            <td 
              className={`pr-3 py-4 text-sm ${enrolledCourse.status === 'active' ? 'text-green-500' : 'text-red-500'}`}
            >
              {capitalize(enrolledCourse?.status)}
            </td>

            {enrolledCourse.status !== 'cancelled' &&
            (includes(['owner', 'admin'], currentUser.role) ||
            (enrolledCourse?.branch?._id?.toString() === currentBranch?._id?.toString())) &&
            <td className="relative py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
              <ActionItems
                currentUser={currentUser}
                enrolledCourse={enrolledCourse}
                onChangeBatch={() => {
                  setSelectedEnrolledCourse(enrolledCourse);
                  setOpenChangeBatchForm(true);
                }}
                // onStatusChange={() => {
                //   setSelectedStudent(student);
                //   setShowStatusModal(true);
                // }}
              />
            </td>}
          </tr>
        ))}
      </Table>

    </CategoryListingLayout>
  )
};

function ActionItems(props) {
  const {
    currentUser,
    enrolledCourse,
    onChangeBatch,
    onStatusChange
  } = props;

  return (
    <ActionMenu>
      {enrolledCourse.batch &&
      <div className="py-1">
        <MenuItem label="Change batch" onClick={onChangeBatch} />
      </div>}
      
      {includes(['owner', 'admin', 'branchAdmin'], currentUser.role) && enrolledCourse.paymentCircle === 'onetime' &&
      <div className="py-1">
        <MenuItem label="Transfer branch" />
      </div>}

      {includes(['owner', 'admin', 'branchAdmin'], currentUser.role) &&
      includes(['active', 'suspended'], enrolledCourse?.status) &&
      <div className="py-1">
        <MenuItem label="Cancel admission" isDanger={true} href={`/students/enrolled-courses/${enrolledCourse._id}/cancel`} />
      </div>}

    </ActionMenu>
  )
};