import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function ActionButton(props) {
  const { label, items, onClick } = props;

  return (
    <div className="inline-flex rounded-md">
      <button
        className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gay-600 ring-1 ring-inset ring-green-600 focus:z-10 cursor-default"
      >
        { label || 'Create new'}
      </button>
      <Menu as="div" className="relative -ml-px block">
        <Menu.Button className="relative inline-flex items-center rounded-r-md bg-green-600 px-2 py-2 text-white ring-1 ring-inset ring-green-600 hover:bg-green-500 focus:z-10">
          <span className="sr-only">Open options</span>
          <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {items?.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <p
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'flex px-4 py-2 text-sm items-center cursor-pointer'
                      )}
                      onClick={() => onClick(item)}
                    >
                      <item.Icon className="h-4 w-4 mr-2" />
                      {item.name}
                    </p>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
};
