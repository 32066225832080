function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
};

export default function SubmitButton(props) {
  const { label, disabled, onClick } = props;
  const color = props.color || 'green';
  
  return (
    <button
      disabled={disabled}
      className={classNames(
        props.className || '',
        `inline-flex justify-center rounded-md bg-${color}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${color}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${color}-600 disabled:opacity-30 disabled:cursor-not-allowed`
      )}
      onClick={onClick}
    >
      {label || 'Add more'}
    </button>
  )
};
