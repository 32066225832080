import http from '../helpers/http';


export const getEnrolledCourse = async (id) => {
  try {
    const { data } = await http.get(`/api/enrolled-courses/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getOnlineEnrolledCourses = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/enrolled-courses/online`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getOnlineEnrolledCourseCount = async (params) => {
  try {
    params = params || {};
    const config = { params }
    const { data } = await http.get(`/api/enrolled-courses/online/count`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const enrollFreeCourse = async (payload) => {
  try {
    const { data } = await http.post(`/api/enrolled-courses/enroll/free`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const enrollOnlineCourse = async (payload) => {
  try {
    const { data } = await http.post(`/api/enrolled-courses/enroll/online`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const createEnrolledCourse = async (payload) => {
  try {
    const { data } = await http.post(`/api/enrolled-courses`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const enrollCourseByAdmin = async (payload) => {
  try {
    const { data } = await http.post(`/api/enrolled-courses/admin`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getEnrolledCoursesForStudent = async (studentId, params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/enrolled-courses/student/${studentId}`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getEnrolledCourses = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/enrolled-courses`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getUserEnrolledCourses = async () => {
  try {
    const { data } = await http.get(`/api/enrolled-courses/user`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getUserEnrolledCourse = async (courseId) => {
  try {
    const { data } = await http.get(`/api/enrolled-courses/course/${courseId}/enrolled`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getEnrolledCourseCount = async (params) => {
  try {
    params = params || {};
    const { data } = await http.get(`/api/enrolled-courses/count`, { params });
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeBatch = async (enrolledCourseId, payload) => {
  try {
    const { data } = await http.put(`/api/enrolled-courses/${enrolledCourseId}/change-batch`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const cancelAdmission = async (enrolledCourseId, payload) => {
  try {
    const { data } = await http.put(`/api/enrolled-courses/${enrolledCourseId}/cancel`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};
