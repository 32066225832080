import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import Layout from './components/Layout';
import { RadioGroupHorizontal } from '../../components/forms/fields';
import { images } from '../../constants';
import { register } from '../../services/auth';
import Footer from '../../layouts/Footer';
import { isEmpty } from 'lodash';


const genderOptions = [
  {name: 'ছাত্র', value: 'male'},
  {name: 'ছাত্রী', value: 'female'},
];

export default function RegistrationPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = location?.state?.redirectUrl;
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [gender, setGender] = useState('male');
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (currentUser) { return navigate('/'); }
  }, []);

  const handleRegister = async (event) => {
    event.preventDefault();
    setUpdating(true);
    let payload = { name, mobile, password, gender };

    try {
      const data = await register(payload);
      navigate(`/auth/otp`, {
        state: {
          userId: data.userId,
          mobile: mobile,
          otpFor: 'registration',
          redirectUrl: redirectUrl
        }
      });
    } catch (error) {
      setUpdating(false);
      toast.error(error.message);
    }
  };

  const isDisabled = () => {
    return updating || isEmpty(name) || isEmpty(mobile) || isEmpty(password) || (password !== confirmPassword);
  };

  return (
    <Layout
      title="ফ্রি রেজিস্ট্রেশন করতে আপনার তথ্য দিন"
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              নাম
            </label>
            <div className="mt-2">
              <input
                name="mobile"
                type="text"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="নাম লিখুন"
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              মোবাইল
            </label>
            <div className="mt-2">
              <input
                name="mobile"
                type="text"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="মোবাইল নাম্বার লিখুন"
                onChange={(event) => setMobile(event.target.value)}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              পাসওয়ার্ড
            </label>
            <div className="mt-2">
              <input
                name="password"
                type="password"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="পাসওয়ার্ড লিখুন"
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              কনফার্ম পাসওয়ার্ড
            </label>
            <div className="mt-2">
              <input
                name="confirmPassword"
                type="password"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="পুনরায় পাসওয়ার্ড লিখুন"
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </div>
          </div>
          <div>
            <RadioGroupHorizontal 
              label="আপনি একজন"
              options={genderOptions}
              onChange={(event) => setGender(event.target.value)}
            />
          </div>

          <div>
            <a href="/terms" target="_blank" className="flex text-xs text-indigo-600 py-2 justify-center text-center">
              রেজিস্ট্রেশন করার মাধ্যমে আপনি সকল শর্তাবলীর সাথে সম্মত হচ্ছেন
            </a>
            <button
              type="submit"
              disabled={isDisabled()}
              className="flex w-full justify-center rounded-md bg-[#27ac1f] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleRegister}
            >
              সাবমিট
            </button>
          </div>
        </form>

        <p className="mt-10 text-center text-sm text-gray-500">
          অ্যাকাউন্ট আছে?{' '}
          <a href="/auth/login" 
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
          >
            লগ ইন করতে এখানে ক্লিক করুন
          </a>
        </p>
      </div>
    </Layout>
  )
}
