import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import toast from 'react-hot-toast';

import StackedLayout from '../../layouts/StackedLayout';
import { Input, UploadIcon, UploadWithDragDrop, TextArea } from '../../components/forms/fields';
import { SubmitButton, Button } from '../../components/buttons';
import { instituteActions } from '../../store/reducers/instituteReducer'
import { handleUploadPublicImage } from '../../utils';
import { getCurrentInstitute, updateInstitute } from '../../services/institute';


const sidebarNavigation = [
  { name: 'General information', href: '/settings/institute-info' },
  { name: 'Bkash credentials', href: '/settings/bkash-credentials' },
  { name: 'Analytics configuration', href: '/settings/analytics' },
];

const defaultUpdateStates = {
  helpline: false,
  address: false,
  addressBN: false,
  brandColor: false,
  website: false,
  facebookUrl: false,
  youtubeUrl: false,
  instagramUrl: false,
  twitterUrl: false
};

export default function InstituteInfoPage() {
  const dispatch = useDispatch();
  const instituteState = useSelector((state) => state.institute);
  const currentInstitute = instituteState?.instituteInfo;
  const [helpline, setHelpline] = useState(currentInstitute?.helpline);
  const [brandColor, setBrandColor] = useState(currentInstitute?.brandColor);
  const [logo, setLogo] = useState(currentInstitute?.logo);
  const [icon, setIcon] = useState(currentInstitute?.icon);
  const [address, setAddress] = useState(currentInstitute?.address);
  const [addressBN, setAddressBN] = useState(currentInstitute?.addressBN);
  const [website, setWebsite] = useState(currentInstitute?.website);
  const [facebookUrl, setFacebookUrl] = useState(currentInstitute?.facebookUrl);
  const [youtubeUrl, setYoutubeUrl] = useState(currentInstitute?.youtubeUrl);
  const [twitterUrl, setTwitterUrl] = useState(currentInstitute?.twitterUrl);
  const [instagramUrl, setInstagramUrl] = useState(currentInstitute?.instagramUrl);
  const [updateStates, setUpdateState] = useState(defaultUpdateStates)

  const onEdit = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = true;
    setUpdateState(_updateState);
  };

  const onCancel = (event, fieldName) => {
    event.preventDefault();
    let _updateState = cloneDeep(updateStates);
    _updateState[fieldName] = false;
    setUpdateState(_updateState);
    if (fieldName === 'helpline') { setHelpline(currentInstitute.helpline); }
    else if (fieldName === 'brandColor') { setAddress(currentInstitute.brandColor); }
    else if (fieldName === 'address') { setAddress(currentInstitute.address); }
    else if (fieldName === 'addressBN') { setAddressBN(currentInstitute.addressBN); }
    else if (fieldName === 'website') { setWebsite(currentInstitute.website); }
    else if (fieldName === 'facebookUrl') { setFacebookUrl(currentInstitute.facebookUrl); }
    else if (fieldName === 'youtube') { setYoutubeUrl(currentInstitute.youtubeUrl); }
    else if (fieldName === 'twitterUrl') { setTwitterUrl(currentInstitute.facebookUrl); }
    else if (fieldName === 'instagramUrl') { setFacebookUrl(currentInstitute.instagramUrl); }
  };

  const onSave = async (event, fieldName, value) => {
    try {
      event.preventDefault();
      let payload = {};
      payload[fieldName] = value;
      await saveInstitute(payload);
      let _updateState = cloneDeep(updateStates);
      _updateState[fieldName] = false;
      setUpdateState(_updateState);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleIconUpload = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setIcon(imageUrl);
      const payload = {icon: imageUrl};
      await saveInstitute(payload);
      return imageUrl;
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const handleLogoUpload = async (files) => {
    try {
      const imageUrl = await handleUploadPublicImage(files);
      setLogo(imageUrl);
      const payload = {logo: imageUrl};
      await saveInstitute(payload);
      return imageUrl;
    } catch (error) {
     toast.error(error.message); 
    }
  };

  const saveInstitute = async (payload) => {
    await updateInstitute(currentInstitute._id, payload);
    const _currentInstitute = await getCurrentInstitute();
    dispatch(instituteActions.setInstituteInfo(_currentInstitute));
  };

  return (
    <StackedLayout
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation="General information"
    >
      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">General Information</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Set your information for your institute
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Name</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentInstitute?.name}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Subdomain</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentInstitute?.subdomain}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Custom domain</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-600">{currentInstitute?.customDomain || 'Not applicable'}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Icon</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div>
                <UploadIcon actionName="Change" image={icon} onUpload={handleIconUpload} />
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Logo</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="w-full">
                <UploadWithDragDrop
                  contentType="image"
                  content={logo} 
                  actionName="Upload a file"
                  onUpload={handleLogoUpload}
              />
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Brand color</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.brandColor && <div className="text-gray-900">{currentInstitute?.brandColor}</div>}
              {updateStates.brandColor &&
              <div className="w-full">
                <Input value={brandColor} onChange={(event) => setBrandColor(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'brandColor', brandColor)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'brandColor')} />
                </div>
              </div>}
              
              {!updateStates.brandColor &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'brandColor')}
              >
                Update
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Hotline</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.helpline && <div className="text-gray-900">{currentInstitute?.helpline}</div>}
              {updateStates.helpline &&
              <div className="w-full">
                <Input value={helpline} onChange={(event) => setHelpline(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'helpline', helpline)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'helpline')} />
                </div>
              </div>}
              
              {!updateStates.helpline &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'helpline')}
              >
                Update
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Address (in English)</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.address && <div className="text-gray-900">{currentInstitute?.address}</div>}
              {updateStates.address &&
              <div className="w-full">
                <TextArea value={address} onChange={(event) => setAddress(event.target.value)} />
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'address', address)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'address')} />
                </div>
              </div>}
              
              {!updateStates.address &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'address')}
              >
                Update
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Address (in Bangla)</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.addressBN && <div className="text-gray-900">{currentInstitute?.addressBN}</div>}
              {updateStates.addressBN &&
              <div className="w-full">
                <TextArea value={addressBN} onChange={(event) => setAddressBN(event.target.value)} />
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'addressBN', addressBN)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'addressBN')} />
                </div>
              </div>}
              
              {!updateStates.addressBN &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'addressBN')}
              >
                Update
              </button>}
            </dd>
          </div>
        </dl>
      </div>

      <div className="mb-10">
        <h2 className="text-base font-semibold leading-7 text-gray-900">Social URLs</h2>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Website</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.website && <div className="text-gray-900">{currentInstitute?.website}</div>}
              {updateStates.website &&
              <div className="w-full">
                <Input value={website} onChange={(event) => setWebsite(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'website', website)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'website')} />
                </div>
              </div>}
              
              {!updateStates.website &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'website')}
              >
                Update
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Facebook link</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.facebookUrl && <div className="text-gray-900">{currentInstitute?.facebookUrl}</div>}
              {updateStates.facebookUrl &&
              <div className="w-full">
                <Input value={facebookUrl} onChange={(event) => setFacebookUrl(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'facebookUrl', facebookUrl)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'facebookUrl')} />
                </div>
              </div>}
              
              {!updateStates.facebookUrl &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'facebookUrl')}
              >
                Update
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Youtube link</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.youtubeUrl && <div className="text-gray-900">{currentInstitute?.youtubeUrl}</div>}
              {updateStates.youtubeUrl &&
              <div className="w-full">
                <Input value={youtubeUrl} onChange={(event) => setYoutubeUrl(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'youtubeUrl', youtubeUrl)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'youtubeUrl')} />
                </div>
              </div>}
              
              {!updateStates.youtubeUrl &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'youtubeUrl')}
              >
                Update
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Instagram link</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.instagramUrl && <div className="text-gray-900">{currentInstitute?.instagramUrl}</div>}
              {updateStates.instagramUrl &&
              <div className="w-full">
                <Input value={instagramUrl} onChange={(event) => setInstagramUrl(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'instagramUrl', instagramUrl)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'instagramUrl')} />
                </div>
              </div>}
              
              {!updateStates.instagramUrl &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'instagramUrl')}
              >
                Update
              </button>}
            </dd>
          </div>
          <div className="pt-6 sm:flex items-center">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Twitter link</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              {!updateStates.twitterUrl && <div className="text-gray-900">{currentInstitute?.twitterUrl}</div>}
              {updateStates.twitterUrl &&
              <div className="w-full">
                <Input value={twitterUrl} onChange={(event) => setTwitterUrl(event.target.value)}/>
                <div className="mt-4 space-x-4">
                  <SubmitButton label="Update" onClick={(event) => onSave(event, 'twitterUrl', twitterUrl)} />
                  <Button label="Cancel" onClick={(event) => onCancel(event, 'twitterUrl')} />
                </div>
              </div>}
              
              {!updateStates.twitterUrl &&
              <button
                className="font-semibold text-green-600 hover:text-green-500"
                onClick={(event) => onEdit(event, 'twitterUrl')}
              >
                Update
              </button>}
            </dd>
          </div>
        </dl>
      </div>
    </StackedLayout>
  )
};
