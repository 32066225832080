import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast';

import AuthLayout from './components/Layout';
import { userActions } from '../../store/reducers/userReducers';
import { login } from '../../services/auth';
import { getCurrentUser } from '../../services/user';


export default function LoginPage() {
  const location = useLocation();
  const redirectUrl = location?.state?.redirectUrl;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const institutteState = useSelector((state) => state.institute);
  const currentInstitute = institutteState?.instituteInfo;
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [registrationNo, setRegistrationNo] = useState('');
  const [isStaff, setIsStaff] = useState(currentInstitute?.permissions?.websiteEnabled ? false : true);
  const [fetchingData, setFetchingData] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    setFetchingData(true);
    let payload = {mobile: mobile, password, isStaff };
    // if (isStaff) {
    //   payload.mobile = mobile;
    // } else {
    //   payload.registrationNo = registrationNo;
    // }

    try {
      const data = await login(payload);
      if (data.passwordResetRequired) {
        navigate(`/auth/reset-password`, {
          state: {
            userId: data._id,
            token: data.token,
            redirectUrl: redirectUrl
          }
        });
      } else {
        localStorage.setItem('currentUser', JSON.stringify(data));
        const currentUser = await getCurrentUser();
        dispatch(userActions.setUserInfo(currentUser));
        if (redirectUrl) {
          navigate(redirectUrl);
        } else if (currentUser?.role === 'student') {
          navigate('/learn/home');
        } else {
          navigate('/services');
        }
      }
    } catch (error) {
      setFetchingData(false);
      toast.error(error.message);
    }
  };

  return (
    <AuthLayout
      title={isStaff ? 'অ্যাডমিন লগ-ইন' : 'শিক্ষার্থী লগ-ইন'}
    >
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST">

          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              মোবাইল
            </label>
            <div className="mt-2">
              <input
                name="mobile"
                type="text"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="মোবাইল নাম্বার লিখুন"
                onChange={(event) => setMobile(event.target.value)}
              />
            </div>
          </div>

          {/* {!isStaff &&
          <div>
            <label className="block text-sm font-medium leading-6 text-gray-900">
              রেজিস্ট্রেশন নাম্বার
            </label>
            <div className="mt-2">
              <input
                name="registrationNo"
                type="text"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="রেজিস্ট্রেশন নাম্বার লিখুন"
                onChange={(event) => setRegistrationNo(event.target.value)}
              />
            </div>
          </div>} */}

          <div>
            <div className="flex items-center justify-between">
              <label className="block text-sm font-medium leading-6 text-gray-900">
                পাসওয়ার্ড
              </label>
              <div className="text-sm">
                <p
                  className="leading-6 text-indigo-600 hover:text-indigo-500 cursor-pointer"
                  onClick={() => {
                    navigate('/auth/forgot-password', {
                      state: {
                        isStaff: isStaff
                      }
                    });
                  }}
                >
                  পাসওয়ার্ড মনে নেই?
                </p>
              </div>
            </div>
            <div className="mt-2">
              <input
                name="password"
                type="password"
                required
                className="block w-full pl-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="পাসওয়ার্ড লিখুন"
                onChange={(event) => setPassword(event.target.value)}
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={!mobile || !password || fetchingData}
              className="flex w-full justify-center rounded-md bg-[#27ac1f] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleLogin}
            >
              লগইন
            </button>
          </div>
        </form>

        {isStaff && currentInstitute?.permissions?.websiteEnabled &&
        <p className="mt-10 text-center text-sm text-gray-500">
          আপনি কি শিক্ষার্থী?{' '}
          <span
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 cursor-pointer"
            onClick={() => setIsStaff(false)}
          >
            তাহলে এখানে ক্লিক করুন
          </span>
        </p>}

        {!isStaff &&
        <p className="mt-2 text-center text-sm text-gray-500">
          অ্যাকাউন্ট নেই?{' '}
          <span
            className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 cursor-pointer"
            onClick={() => {
              navigate(`/auth/register`, { state: { redirectUrl: redirectUrl }})
            }}
          >
            ফ্রি রেজিস্ট্রেশন করতে এখানে ক্লিক করুন
          </span>
        </p>}

        {!isStaff &&
        <p className="mt-10 text-center text-sm text-gray-500">
          আপনি কি অ্যাডমিন?{' '}
          <span
            className="font-semibold leading-6 underline text-gray-600 hover:text-gray-500 cursor-pointer"
            onClick={() => setIsStaff(true)}
          >
            তাহলে এখানে ক্লিক করুন
          </span>
        </p>}
      </div>
    </AuthLayout>
  )
};
