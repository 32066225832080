import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/24/outline';

import StackedLayout from '../../../layouts/StackedLayout';
import LiveClassInstruction from '../../../components/LiveClassInstruction';
import { SubmitButton } from '../../../components/buttons';
import { getEnrolledCourse } from '../../../services/enrolledCourse';
import { getBanglaDateWithDay } from '../../../utils';



export default function CourseEnrollSuccessPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState?.userInfo || null;
  const params = useParams();
  const navigate = useNavigate();
  const enrolledCourseId = params.id;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) { return navigate(-1); }
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await getEnrolledCourse(enrolledCourseId);
      setData(data);
    } catch (error) {
      navigate(-1);
    }
  };

  return (
    <StackedLayout
      loading={loading}
      hideFooter={true}
    >
      <div className="flex flex-col items-center justify-center">
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              আপনি সফলভাবে কোর্সটিতে ভর্তি হয়েছেন
            </h3>
            <div className="mt-2">
              <p className="text-sm text-purple-700">
                কোর্সের নাম: {data?.course?.name}
              </p>
              {data?.course?.courseType === 'live' &&
              <p className="text-sm text-gray-500">
                ব্যাচের নাম: {data?.batch?.name}
              </p>}

              {data?.course?.courseType === 'live' &&
              <p className="text-sm text-gray-500">
                ক্লাস শুরু: {getBanglaDateWithDay(data?.batch?.startDate)}
              </p>}
            </div>
          </div>
        </div>

        {data?.course?.courseType === 'live' && data?.batch?.liveClassPlatform &&
        <LiveClassInstruction currentUser={currentUser} batch={data?.batch} page="success" />}

        <div className="mt-5 sm:mt-6">
          <SubmitButton
            label="কোর্সটিতে অধ্যয়ন শুরু করুন"
            onClick={() => navigate(`/learn/courses/${data?.course?._id}/details`)}
          />
        </div>
      </div>

    </StackedLayout>
  )
};