import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import StackedLayout from '../../../layouts/StackedLayout';
import PaymentMethods from '../../../components/PaymentMethods';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import { images } from '../../../constants';
import { getCourse } from '../../../services/course';
import { getBatch } from '../../../services/batch';
import { getBranch } from '../../../services/branch';
import { bkashCheckout } from '../../../services/payment';
import {
  convertToBanglaNumber,
  getDiscountedFee,
  gradeLabelMapping,
  groupLabelMapping,
} from '../../../utils';
import { includes } from 'lodash';


const courseTypeMapping = {
  live: 'লাইভ কোর্স',
  recorded: 'রেকর্ডেড কোর্স',
  exam: 'অনলাইন মডেল টেস্ট',
  offline: 'অফলাইন'
};

const paymentMethods = [
  { title: 'বিকাশ', value: 'bkash', icon: images.Bkash },
];

export default function CourseCheckoutPage() {
  const userState = useSelector((state) => state.user);
  const currentUser = userState.userInfo || null;
  const navigate = useNavigate();
  const params = useParams();
  const courseId = params.courseId;
  const batchId = params.batchId;
  const branchId = params.branchId;
  const queryParams = new URLSearchParams(useLocation().search);
  const status = queryParams.get('status');
  const message = queryParams.get('message');
  const [course, setCourse] = useState(null);
  const [batch, setBatch] = useState(null);
  const [branch, setBranch] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0]);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!currentUser) { return navigate('/auth/login'); }
    if (!courseId) { return navigate(-1); }

    async function _fetchData() {
      await fetchCourse();
      setLoading(false);
    };
    _fetchData();
  }, []);

  const fetchCourse = async () => {
    try {
      const _course = await getCourse(courseId);
      if (!_course) { return navigate(-1); }
      setCourse(_course);

      if (includes(['live', 'offline'], _course.courseType)) {
        const _batch = await getBatch(batchId);
        if (!_batch) {
          return navigate(-1);
        }
        setBatch(_batch);
      }

      if (_course.courseType === 'offline') {
        const _branch = await getBranch(branchId);
        if (!_branch) {
          return navigate(-1);
        }
        setBranch(_branch);
      }
    } catch (error) {
      toast.error(error.message);
      navigate(-1);
    }
  };

  const pay = async () => {
    try {
      setProcessing(true);
      if (selectedPaymentMethod === 'bkash') {
        const payload = {
          amount: getDiscountedFee(course),
          transactionType: 'course',
          course: course._id,
          program: course.program._id,
          batch: batchId,
          branch: branchId
        };
        const bkashData = await bkashCheckout(payload);
        if (bkashData?.transactionStatus === 'Initiated') {
          window.location.href = bkashData.bkashURL;
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setProcessing(false);
    }
  };
 
  return (
    <StackedLayout loading={loading}>
      {status === 'failed' && <ErrorAlert message={message} />}
      
      <div className="mx-auto max-w-7xl px-4 pb-16 sm:px-6 sm:pb-24 sm:pt-8 lg:px-8 xl:px-2">
        <div className="mx-auto grid max-w-lg grid-cols-1 gap-x-8 gap-y-4 sm:gap-y-16 lg:max-w-none lg:grid-cols-2">
          <div className="mx-auto w-full max-w-lg">
            <div className="flow-root">
              <div className="flex sm:space-x-6">
                <img
                  src={course?.thumbnail}
                  alt=""
                  className="hidden sm:block h-24 sm:h-24 flex-none rounded-md bg-gray-100 object-cover object-center"
                />
                <div className="flex-auto">
                  <div className="space-y-1 sm:flex sm:items-start sm:justify-between sm:space-x-6">
                    <div className="flex-auto space-y-1 text-sm font-medium">
                      <h3 className="text-gray-900 text-xl">
                        <a href={`/learn/courses/${courseId}/details`}>{course?.name}</a>
                      </h3>
                      <div className="flex items-center mt-2 text-sm text-gray-500 space-x-1">
                        <p>{courseTypeMapping[course?.courseType]}</p>
                        <div className="h-1 w-1 rounded-full bg-gray-500" />
                        <p>{course?.program?.nameBN || course?.program?.name}</p>
                        {/* <p>{gradeLabelMapping[course.grade]}{course?.group ? ` (${groupLabelMapping[course.group]})` : ''}</p> */}
                      </div>
                      {course?.courseType === 'offline' && <p className="font-normal text-gray-600">শাখাঃ {branch?.nameBN || branch?.name}</p>}
                      {includes(['live', 'offline'], course?.courseType) && <p className="font-normal text-gray-600">ব্যাচঃ {batch?.nameBN || batch?.name}</p>}

                      {includes(['recorded', 'exam'], course?.courseType) && course?.validity > 0 &&
                      <p className="text-indigo-600">
                        কোর্সটির মেয়াদ{' '}
                        <span className="font-semibold">
                          {convertToBanglaNumber(course?.validity)}
                        </span>
                        {' '}বছর
                      </p>}

                      {/* <div className="mt-6 flex items-center text-sm text-gray-500 space-x-1">
                        <img className="h-6 w-6" src={course?.institute?.icon} alt="" />
                        <p>{course?.institute?.name}</p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <dl className="mt-4 sm:mt-10 space-y-6 text-sm font-medium text-gray-500">
              <div className="flex justify-between">
                <dt>কোর্স ফি</dt>
                <dd className="text-gray-900">৳{convertToBanglaNumber(course?.fee)}</dd>
              </div>
              <div className="flex justify-between">
                <dt>
                  ডিস্কাউন্ট{course?.discount > 0 && course?.discountType === 'percentage' ? ` (${convertToBanglaNumber(course?.discount)})%` : ''}
                  {/* <span className="text-xs text-red-400"> (-)</span> */}
                </dt>
                <dd className="text-gray-900">৳{convertToBanglaNumber(getDiscountAmount(course))}</dd>
              </div>
              <div className="flex justify-between">
                <dt>
                  প্রমোশনাল ডিস্কাউন্ট
                  {/* <span className="text-xs text-red-400"> (-)</span> */}
                </dt>
                <dd className="text-gray-900">৳০</dd>
              </div>
              <div className="flex justify-between border-t border-gray-200 pt-2 sm:pt-6 text-gray-900">
                <dt className="text-base">সর্বমোট <span className="text-xs text-gray-500">(*সকল ভ্যাট ও ট্যাক্সসহ)</span></dt>
                <dd className="text-base">৳{convertToBanglaNumber(getDiscountedFee(course))}</dd>
              </div>
            </dl>
          </div>

          <div className="mx-auto mt-2 sm:mt-0 w-full max-w-lg">
            <div className="pb-10">
              <PaymentMethods onSelect={(method) => setSelectedPaymentMethod(method)} />
            </div>

            <p className="mb-2 text-sm text-center underline text-indigo-600">
              <a href="/terms" target="_blank">এই পেমেন্ট করার জন্য আমি শর্তাবলী বুঝেছি</a>
            </p>
            <button
              type="button"
              disabled={!selectedPaymentMethod || processing}
              className="flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 py-2 text-white hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-900 focus:ring-offset-2 disabled:cursor-none disabled:bg-gray-100"
              onClick={() => pay()}
            >
              পেমেন্ট করুন
            </button>
          </div>
        </div>
      </div>
    </StackedLayout>
  )
};

function getDiscountAmount(course) {
  const fee = course?.fee;
  const discountValue = course?.discount || 0;

  return course?.discountType === 'flat' ? discountValue : (fee * discountValue) / 100;
}