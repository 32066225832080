import http from '../helpers/http';


export const createCoachingStudent = async (payload) => {
  try {
    const { data } = await http.post(`/api/students/coaching`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getCoachingStudents = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/students/coaching`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getBranchStudents = async (params) => {
  try {
    params = params || {};
    const config = { params };
    const { data } = await http.get(`/api/students/branch`, config);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getStudent = async (id) => {
  try {
    const { data } = await http.get(`/api/students/${id}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const getStudentByRegistrationNumber = async (registrationNo) => {
  try {
    const { data } = await http.get(`/api/students/registration/${registrationNo}`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const updateStudent = async (id, payload) => {
  try {
    const { data } = await http.put(`/api/students/${id}`, payload);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};

export const changeStudentStatus = async (id) => {
  try {
    const { data } = await http.put(`/api/students/${id}/status`);
    return data;
  } catch (error) {
    const err = new Error(error?.response?.data?.message || error?.message || 'Something went wrong.');
    throw err;
  }
};