function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Tabs(props) {
  const { tabs, selectedTab, onChange } = props;

  return (
    <div className="mb-4">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">Select a tab</label>
        <select
          className="block w-full rounded-md border-gray-300 focus:border-green-500 focus:ring-green-500"
          defaultValue={selectedTab}
          onChange={(event) => onChange(event)}
        >
          {tabs.map((tab, index) => (
            <option key={`tab-tab-option-index[${index}]`} value={tab} index={index}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab, index) => (
              <p
                key={`tab-tab-index[${index}]`}
                className={classNames(
                  tab === selectedTab
                    ? 'border-green-500 text-green-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer'
                )}
                onClick={(event) => onChange(event, index)}
                aria-current={tab === selectedTab ? 'page' : undefined}
              >
                {tab}
              </p>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}