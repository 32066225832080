import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { PlayIcon, ListBulletIcon, FolderIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { forEach, isEmpty, omit, orderBy } from 'lodash';

import StackedLayout from '../../layouts/StackedLayout';
import FolderBreadcrumb from '../../components/FolderBreadcrumb';
import { ButtonWithIcon, SubmitButton } from '../../components/buttons';
import InputModal from '../../components/modals/InputModal';
import LessonForm from './components/LessonForm';
import Table from '../../components/tables/StackedTable';
import { ActionMenu, MenuItem } from '../../components/ActionMenu';
import ConfirmModal from '../../components/modals/ConfirmModal';
import VideoUploader from '../../components/VideoUploader';
import UploadProgressPanel from '../../components/UploadProgressPanel';
import { getFolder, createFolder, getFolders, updateFolder, deleteFolder } from '../../services/folder';
import { getLessons, createLesson, updateLesson, deleteLesson, removeLessonVideo } from '../../services/lesson';


const sidebarNavigation = [
  { name: 'Video Lessons', lessonType: 'lesson', href: '/contents/lessons' },
  // { name: 'Playlists', lessonType: 'playlist', href: '/contents/lessons/playlists' },
];
const lessonHeaders = ['Name', 'Created by', 'Status', 'Duration', 'Size'];
const playlistHeaders = ['Name', 'Created by', 'Lesson count'];

export default function VideoLessonsPage(props) {
  const params = useParams();
  const navigate = useNavigate();
  const folderId = params?.folderId;
  const { lessonType } = props;
  const instituteState = useSelector((state) => state.institute);
  const cuurentInstitute = instituteState?.instituteInfo;
  const [collectionId, setCollectionId] = useState(cuurentInstitute?.gotipathCollectionId);
  const [items, setItems] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagingData, setPagingData] = useState(null);
  const [queryString, setQueryString] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [activeUploads, setActiveUploads] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [openFolderModal, setOpenFolderModal] = useState(false);
  const [openVideoUploader, setOpenVideoUploader] = useState(false);
  const [showLessonDeleteModal, setShowLessonDeleteModal] = useState(false);
  const [showFolderDeleteModal, setShowFolderDeleteModal] = useState(false);
  const [showRemoveVideoModal, setShowRemoveVideoModal] = useState(false);
  const [loading, setLoading] = useState(true);

  window.onbeforeunload = function () {
    if (activeUploads.length > 0) {
        return "If you reload this page, your previous action will be repeated";
    } else {
        return;
    }
  };

  useEffect(() => {
    async function fetchFolder() {
      if (folderId) {
        try {
          const _folder = await getFolder(folderId);
          if (!_folder) { return navigate(-1); }
          setCurrentFolder(_folder);
          setCollectionId(_folder.gotipathCollectionId);
        } catch (error) {
          navigate(-1);
        }
      }
    };
    fetchFolder();
  }, []);

  useEffect(() => {
    async function _fetchData() {
      await fetchData();
      setLoading(false);
    };
    _fetchData();
  }, [
    lessonType,
    queryString,
    currentPage,
    currentFolder
  ]);

  const fetchData = async () => {
    try {
      if (lessonType === 'lesson') {
        const lessons = await fetchLessons();
        let folders = await fetchFolders();
        forEach(folders, folder => folder.isFolder = true);
        const _items = folders.concat(lessons);
        setItems(_items);
      } else {
        const playlists = await fetchLessons();
        setItems(playlists);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchLessons = async () => {
    try {
      let params = {
        lessonType: lessonType,
        search: queryString
      };

      if (lessonType === 'lesson' && currentFolder) {
        params.folder = currentFolder._id;
      }

      if (lessonType === 'playlist') {
        params = {
          lessonType: 'playlist',
          isPaging: true,
          page: currentPage,
          limit: 10,
          search: queryString,
          sortBy: 'createdAt',
          sortOrder: 'asc'
        }
      }

      const data = await getLessons(params);
      if (lessonType === 'playlist') {
        const playlists = data.docs || [];
        setPagingData(omit(data, 'docs'));
        return playlists;
      } else {
        return orderBy(data, 'title');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchFolders = async () => {
    try {
      let params = {
        contentType: 'lesson',
        search: queryString,
      };

      if (currentFolder) {
        params.parentFolder = currentFolder._id;
      } else {
        params.isRoot = true;
      }

      const data = await getFolders(params);
      return orderBy(data, 'name');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleCreateNew = (itemName) => {
    if (itemName === 'folder') {
      setOpenFolderModal(true);
    } else if (itemName === 'lesson') {
      setOpenForm(true);
    }
  };

  const onSearch = async (event, queryString) => {
    event.preventDefault();
    const q = !isEmpty(queryString) ? queryString: null;
    setQueryString(q);
    setLoading(true);
  };

  const saveLesson = async (event, payload) => {
    try {
      event?.preventDefault();
      if (selectedLesson?._id) {
        await updateLesson(selectedLesson?._id, payload);
        toast.success(`Lesson updated successfully.`);
      } else {
        if (lessonType === 'lesson' && currentFolder) {
          payload.folder = currentFolder._id;
        }
        await createLesson(payload);
        toast.success(`Lesson created successfully.`);
      }
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const saveFolder = async (event, name) => {
    try {
      event.preventDefault();
      if (selectedFolder) {
        const payload = { name: name };
        await updateFolder(selectedFolder._id, payload);
        toast.success('Folder updated successfully.');
      } else {
        let payload = { name: name, contentType: 'lesson' };
        if (currentFolder) { payload.parentFolder = currentFolder._id };
        await createFolder(payload);
        toast.success('Folder created successfully');
      }
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    }
  };

  const handleDeleteFolder = async (event) => {
    try {
      event.preventDefault();
      await deleteFolder(selectedFolder._id);
      toast.success('Folder deleted successfully');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    } finally {
      setSelectedFolder(null);
    }
  };

  const handleDeleteLesson = async (event) => {
    try {
      event.preventDefault();
      await deleteLesson(selectedLesson._id);
      const _activeUploads = activeUploads.filter(upload => upload.videoId !== selectedLesson.videoId);
      setActiveUploads(() => _activeUploads);
      toast.success('Lesson deleted successfully');

      await fetchData();
    } catch (error) {
      toast.error(error.message);
      throw error;
    } finally {
      setSelectedLesson(null);
    }
  };

  const handleUploadStart = async (lessonId, videoId, title, uppy) => {
    try {
      const newUpload = { videoId, title, uppy };
      setActiveUploads(() => [...activeUploads, newUpload]);
  
      const paylaod = { status: 'uploading' };
      await updateLesson(lessonId, paylaod);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUploadError = async (lessonId, videoId) => {
    try {
      const _activeUploads = activeUploads.filter(upload => upload.videoId !== videoId);
      setActiveUploads(() => _activeUploads);

      const paylaod = { status: 'draft' };
      await updateLesson(lessonId, paylaod);
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUploadComplete = async (lessonId, videoId) => {
    try {
      const _activeUploads = activeUploads.filter(upload => upload.videoId !== videoId);
      setActiveUploads(() => _activeUploads);

      const paylaod = { status: 'processing' };
      await updateLesson(lessonId, paylaod);
      await fetchData();
    } catch (error) {
      toast.error(error.message); 
    }
  };

  const handleRemodeVideo = async (event) => {
    try {
      event.preventDefault();
      await removeLessonVideo(selectedLesson._id);
      const _activeUploads = activeUploads.filter(upload => upload.videoId !== selectedLesson.videoId);
      setActiveUploads(() => _activeUploads);
      toast.success('Video removed successfully.');
      await fetchData();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onPageChange = async (event, action) => {
    event.preventDefault();
    const page = action === 'next' ? pagingData.page + 1 : pagingData.page - 1;
    setCurrentPage(page);
  };

  return (
    <StackedLayout
      loading={loading}
      sidebarNavigation={sidebarNavigation}
      currentSidebarNavigation={lessonType === 'lesson' ? 'Video Lessons' : 'Playlists'}
    >
      {openFolderModal &&
      <InputModal
        label={selectedFolder ? 'Rename folder' : 'New folder name'}
        currentValue={selectedFolder?.name}
        actionName={selectedFolder ? 'Rename' : 'Create'}
        onSubmit={saveFolder}
        onCancel={() => setOpenFolderModal(false)}
      />}

      {openForm &&
      <LessonForm
        lesson={selectedLesson}
        lessonType={lessonType}
        onSave={saveLesson}
        onClose={() => {
          setOpenForm(false);
          setSelectedLesson(null);
        }}
      />}

      {openVideoUploader &&
      <VideoUploader
        lesson={selectedLesson}
        collectionId={collectionId}
        onUploadStart={handleUploadStart}
        onUploadComplete={handleUploadComplete}
        onUploadError={handleUploadError}
        onClose={async () => {
          setSelectedLesson(null);
          setOpenVideoUploader(false);
          await fetchData();
        }}
      />}

      {showLessonDeleteModal &&
      <ConfirmModal
        title={lessonType === 'lesson' ? 'Delete lesson' : 'Delete playlist'} 
        description={`Are you sure to delete ${lessonType}? Please note that you can't retrive data once this item is deleted.`}
        actionName="Delete"
        onConfirm={handleDeleteLesson}
        onCancel={() => {
          setSelectedLesson(null);
          setShowLessonDeleteModal(false);
        }}
      />}

      {showFolderDeleteModal &&
      <ConfirmModal
        title="Delete folder"
        description={`Are you sure to delete folder? Please note that all the child folders and contents for this folder will be deleted as well.`}
        actionName="Delete"
        onConfirm={handleDeleteFolder}
        onCancel={() => {
          setSelectedFolder(null);
          setShowFolderDeleteModal(false);
        }}
      />}

      {showRemoveVideoModal &&
      <ConfirmModal
        title="Remove video"
        description={`Are you sure to remove video? Please note that you can't retrive data once this item is deleted.`}
        actionName="Remove"
        onConfirm={handleRemodeVideo}
        onCancel={() => {
          setSelectedLesson(null);
          setShowRemoveVideoModal(false);
        }}
      />}
      
      {/* <div className="mx-2 flex justify-end">
        <div className="float-right">
          {lessonType === 'lesson' ?
          <ActionButton items={createNewItems} onClick={handleCreateNew} />
          : <SubmitButton label="Create playlist" onClick={() => setOpenForm(true)} />}
        </div>
      </div> */}

      <div className="mx-2 flex justify-end space-x-4">
        <ButtonWithIcon Icon={FolderIcon} label="New folder" onClick={() => handleCreateNew('folder')} />
        <ButtonWithIcon Icon={PlayIcon} label="New lesson" onClick={() => handleCreateNew('lesson')} />
      </div>

      {currentFolder &&
      <div className="px-4 sm:px-6 lg:px-8">
        <FolderBreadcrumb currentFolder={currentFolder} homeUrl="/contents/lessons" />
      </div>}

      <div className="flex flex-col">
        <Table
          itemsCount={items.length}
          headers={lessonType === 'lesson' ? lessonHeaders : playlistHeaders}
          queryString={queryString}
          onSearch={onSearch}
          pagingData={lessonType === 'playlist' && pagingData}
          onPageChange={lessonType === 'playlist' && onPageChange}
        >
          {items?.map((item) => (
            <tr key={item._id} className="text-sm text-center">
              <td className="py-4 pl-4 pr-3 text-left font-medium text-gray-900 sm:pl-0 text-ellipsis">
                <div className="flex items-center">
                  <div>
                    {item.isFolder ?
                    <FolderIcon className="h-5 w-5" />
                    : (
                      item.lessonType === 'lesson' ? <PlayIcon className="h-5 w-5" /> : <ListBulletIcon className="h-5 w-5" />
                    )}
                  </div>
                  <div className="ml-2">
                    <div className="font-medium text-gray-900">
                      {item.isFolder ?
                      <a
                        href={`/contents/lessons/folder/${item._id}`}
                        className="hover:underline"
                      >
                        {item.name}
                      </a>
                      : 
                      <p
                        className="cursor-pointer hover:underline"
                        onClick={() => {
                          setSelectedLesson(item);
                          setOpenForm(true);
                        }}
                      >
                        {item.title}
                      </p>}
                    </div>
                  </div>
                </div>
              </td>
              <td className="pr-3 py-4 text-sm text-gray-500">
                <div className="flex items-center justify-center">
                  <div>
                    {item?.createdBy?.profilePicture ?
                    <img className="h-5 w-5 rounded-full" src={item.createdBy.profilePicture} />
                    : <UserCircleIcon className="h-5 w-5" />}
                  </div>
                  <div className="ml-2">
                    <div className="font-medium text-gray-900">
                      {item?.createdBy?.name.split(' ').slice(0, 2).join(' ')}
                    </div>
                  </div>
                </div>
              </td>
              {lessonType === 'lesson' && <td className="pr-3 py-4 text-sm text-gray-500">
                {item.isFolder ? '-' : item?.status === 'draft' ? 'No video' : item.status}
              </td>}
              {lessonType === 'lesson' && <td className="pr-3 py-4 text-sm text-gray-500">{item?.formattedDuration || '-'}</td>}
              {lessonType === 'lesson' && <td className="pr-3 py-4 text-sm text-gray-500">{item?.size || '-'}</td>}
              {lessonType === 'playlist' && <td className="pr-3 py-4 text-sm text-gray-500">{item?.lessons?.length || 0}</td>}

              <td className="py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                {item.isFolder ?
                <FolderActionItems
                  onRename={() => {
                    setSelectedFolder(item);
                    setOpenFolderModal(true);
                  }}
                  onDelete={() => {
                    setSelectedFolder(item);
                    setShowFolderDeleteModal(true);
                  }}
                />
                :
                <ActionItems
                  lesson={item}
                  onEdit={() => {
                    setSelectedLesson(item);
                    setOpenForm(true);
                  }}
                  onAddVideo={() => {
                    setSelectedLesson(item);
                    setOpenVideoUploader(true);
                  }}
                  onRemoveVideo={() => {
                    setSelectedLesson(item);
                    setShowRemoveVideoModal(true);
                  }}
                  onDelete={() => {
                    setSelectedLesson(item);
                    setShowLessonDeleteModal(true);
                  }}
                />}
              </td>
            </tr>
          ))}
        </Table>
      </div>

      {activeUploads.length > 0 && <UploadProgressPanel activeUploads={activeUploads} />}
    </StackedLayout>
  )
};

function ActionItems(props) {
  const { lesson, onEdit, onAddVideo, onRemoveVideo, onDelete } = props;
  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Edit" onClick={onEdit} />
      </div>

      {lesson.lessonType === 'lesson' && lesson.status === 'draft' &&
      <div className="py-1">
        <MenuItem label="Add video" onClick={onAddVideo} />
      </div>}

      {lesson.lessonType === 'lesson' && lesson.status !== 'draft' && lesson.streamingPlatform === 'gotipath' &&
      <div className="py-1">
        <MenuItem label="Remove video" onClick={onRemoveVideo} />
      </div>}
      
      <div className="py-1 text-red-600">
        <MenuItem label="Delete" isDanger={true} onClick={onDelete} />
      </div>
    </ActionMenu>
  )
};

function FolderActionItems(props) {
  const { onRename, onDelete } = props;
  return (
    <ActionMenu>
      <div className="py-1">
        <MenuItem label="Rename" onClick={onRename} />
      </div>
      
      {/* <div className="py-1 text-red-600">
        <MenuItem label="Delete" isDanger={true} onClick={onDelete} />
      </div> */}
    </ActionMenu>
  )
};
