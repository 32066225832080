export default function Button(props) {
  const {
    label,
    disabled,
    onClick,
    className
  } = props;


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  };
  
  return (
    <button
      disabled={disabled}
      className={classNames(
        'inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  disabled:opacity-30 disabled:cursor-not-allowed',
        className || '',
      )}
      onClick={onClick}
    >
      {label || 'Cancel'}
    </button>
  )
}
